<template>
	<div class="wrapper" v-show='viewShow'>
		<section class="wrapper_top"><img :src="require('@/assets/image/fh2.png')" alt="" @click="fh()" /> 預約詳情</section>
		<section class="wrapper_centent" v-if="renderData.length!=0">  
			<div class="top_text">{{renderData. testStatus == 0 ?'等待考試': renderData. testStatus == 1 ?'待評分':renderData.testStatus == 2?'已取消':renderData.testStatus == 3?'已完成考試':renderData.testStatus == 4 ?'已失效':'' }}</div> 
			<div class="top_number" v-if="renderData.testStatus!=2 &&renderData.testStatus!=4">
				<span>你的考生號為：</span>
				<span>{{ renderData.testCode }}</span>
				<div @click="copyBut">複製</div>
			</div> 
			
			<div class="top_number2" v-if="renderData.testStatus==2&&renderData.testStatus!=4">取消時間：{{renderData.cancelTime}}</div>
			
			<div class="uls">
				<div class="lis lis_hr">
					<span>證件姓名</span>
					<div>{{renderData.testName}}</div> 
				</div>
				<div class="lis lis_hr">
					<span>證件英文姓名</span>
					<div>{{renderData.testNamePinyin}}</div> 
				</div>
				<div class="lis lis_hr">
					<span>手機號</span>
					 <div>+{{ renderData.areaCode }}-{{ renderData.phone }}</div> 
				</div> 
				<div class="lis lis_hr">
					<span>電郵</span>
					<div>{{renderData.email}}</div> 
				</div>
				<div class="lis">
					<span>預約時間</span>
					<div>{{ renderData.testDate.split(' ')[0] }}&nbsp;{{ renderData.testStartTime }}</div> 
				</div>
			</div>
			
			<div class="uls"> 
				<div class="lis lis_hr">
					<span>證件類型</span>
					<div>{{ renderData.idType === 0 ? '身份證' : renderData.idType == 1 ? '護照' : null }}</div> 
				</div>
				<div class="lis">
					<span>證件號</span>
					<div>{{ renderData.idNo }}</div> 
				</div>
			</div>
		</section> 
		
		<div class="fromBut" @click="fromBut" v-if="renderData.testStatus==0">取消預約</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex';  
export default {
	components: {},
	name: 'reservationFormState',
	data() {
		return {  
			studentNumber: '202301108899' ,//学生编号
			renderData:{},
		};
	},
	watch: {},
	components: {},
	created() {
		console.log(this.$route.query)
		this.renderData = JSON.parse(this.$route.query.list) 
	},
	mounted() {
	 
	},
	destroyed(){
		 
	},
	computed: {
		 ...mapState(['isPc','viewShow']),
		viewShow(){
		  return this.$store.state.viewShow;
		}
	},
	methods: {
		fh() {
			history.go(-1); //返回首页	 
		},
		copyBut() {
			this.copy(this.renderData.testCode);
		},
		copy(e) {
			var input = document.createElement('input'); // 直接构建input
			input.value = e; // 设置内容
			document.body.appendChild(input); // 添加临时实例
			input.select(); // 选择实例内容
			document.execCommand('Copy'); // 执行复制
			document.body.removeChild(input); // 删除临时实例
			this.$toast('复制成功');
		},
		fromBut() { 
			this.$router.push({
			    name:'cancelReservation',
			    query:{
			    	list:JSON.stringify(this.renderData)
			    }
			})
		}
	}
};
</script>
<style lang="scss">
@import '@/assets/css/index.scss';


</style>

<style lang="scss" scoped>
@import '@/assets/css/index.scss';

.wrapper {
	width: 100%;
	height: 100%;
	position: relative;
	overflow-x: hidden;
	background: #fafafa;
	padding-top: px2rem(80);
	box-sizing: border-box;

	.wrapper_top {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: px2rem(80);
		text-align: center;
		line-height: px2rem(80);
		font-size: px2rem(36);
		background: #ffffff;
		font-family: PingFang SC-Semibold, PingFang SC;
		font-weight: 600;
		color: #000000;
		
		img {
			width: px2rem(56);
			height: px2rem(56);
			position: absolute;
			left: px2rem(32);
			top: 0;
			bottom: 0;
			margin: auto;
		}
	}

	.wrapper_centent {
		width: 100%;
		padding: px2rem(32);
		padding-top: px2rem(48);
		box-sizing: border-box;
 

		.top_text { 
			text-align: center;
			font-size: px2rem(36);
			font-family: PingFang SC-Semibold, PingFang SC;
			font-weight: 600;
			color: #333333;
			line-height: px2rem(38);
		}

		.top_number {
			margin-top: px2rem(22);
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;

			span {
				font-size: px2rem(28);
				font-family: PingFang SC-Regular, PingFang SC;
				font-weight: 400;
			}

			span:nth-child(1) {
				color: #999999;
			}

			span:nth-child(2) {
				color: #333333;
			}

			div {
				margin-left: px2rem(16);
				width: px2rem(96);
				height: px2rem(40);
				line-height: px2rem(40);
				font-size: px2rem(24);
				font-family: PingFang SC-Regular, PingFang SC;
				font-weight: 400;
				color: #ffffff;
				background: #122ab0;
				border-radius: px2rem(20);
			}
		}
		
		.top_number2{
			margin-top: px2rem(22);
			font-size: px2rem(28);
			font-family: PingFang SC-Regular, PingFang SC;
			font-weight: 400;
			color: #333333;
			line-height: px2rem(33);
			text-align: center;
		}
		
		.uls {
			width: 100%;
			background: #ffffff;
			margin-top: px2rem(48);
			padding-left: px2rem(32);
			padding-right: px2rem(32);
			box-sizing: border-box;
			margin-bottom: px2rem(18);
			border-radius: px2rem(16);
		
			.lis {
				width: 100%;
				height: px2rem(104);
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: px2rem(28);
				font-family: PingFang SC-Regular, PingFang SC;
		
				span {
					font-size: px2rem(28);
					font-family: PingFang SC-Regular, PingFang SC;
					font-weight: 400; 
					color: #999999;
					white-space: nowrap;
				}
				
				div{
					font-size: px2rem(28);
					font-family: PingFang SC-Regular, PingFang SC;
					font-weight: 400; 
					color: #333333;
					white-space: nowrap;
				}
		 
			}
		
			.lis_hr {
				position: relative;
			}
			.lis_hr::after {
				content: ' ';
				width: 100%;
				height: px2rem(2);
				background: #f2f2f2;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0%;
				margin: auto;
			}
		}
		
	}
	
	
	.fromBut {
		width: calc(100% - #{px2rem(32)} - #{px2rem(32)});
		position: fixed;
		bottom: 3%;
		left: 0;
		right: 0;
		padding-left: px2rem(32);
		padding-right: px2rem(32);
		box-sizing: border-box;
		height: px2rem(88);
		line-height: px2rem(88);
		background: #122ab0;
		border-radius: px2rem(92);
		margin: auto;
		font-size: px2rem(32);
		font-family: PingFang SC-Semibold, PingFang SC !important;
		font-weight: 600;
		color: #ffffff;
		box-shadow: 0px px2rem(8) px2rem(8) 0px rgba(18, 42, 176, 0.3);
	}
}
</style>
